import "firebase/auth";
import "firebase/database";
import { firebase } from "@firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsdW8JCYIbEGB1L_qy7wjl5dakIra_pf8",
  authDomain: "greenratio.firebaseapp.com",
  databaseURL:
    "https://greenratio-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "greenratio",
  storageBucket: "greenratio.appspot.com",
  messagingSenderId: "86303838941",
  appId: "1:86303838941:web:eb0fa3c6adbf4057538134",
  measurementId: "G-5L0K9M78LX",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };

// //! Is init?
// module.exports.isInit = function () {
//   if (init !== null) {
//     return { init: init, config: firebaseConfig };
//   } else {
//     return;
//   }
// };

//! Log in
export const FBlogIn = async function (
  email,
  password,
  failedAttempt,
  callback = () => {}
) {
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((value) => {
      document.location.reload(true);
    })
    .catch((error) => {
      failedAttempt(error);
    });
};

//! Sign up
export const FBsignUp = async function (
  email,
  password,
  failedAttempt,
  values
) {
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      let user = userCredential.user;

      user
        .updateProfile(values)
        .then(function (val) {
          console.log(val);
          return true;
          // Update successful.
        })
        .catch(function (error) {
          console.log(error);
          // An error happened.
        });
    })
    .catch((error) => {
      failedAttempt(error);
    });
};

// ! Signout
export const signOut = function () {
  firebase
    .auth()
    .signOut()
    .then(() => {
      document.location.reload(true);
      return;
      // Sign-out successful.
    })
    .catch((error) => {
      return error;
      // An error happened.
    });
};

// ! Authentication listener
export const connectToAuth = function (callback) {
  firebase.auth().onAuthStateChanged(callback);
};

//! Get userData
export const FBgetUserData = firebase.auth().currentUser;

//! Update profile
export const FBupdateProfile = function (values) {
  let user = firebase.auth().currentUser;

  let keys = Object.keys(values);
  console.log("updating");
  console.log(keys);
  console.log(values);

  user
    .updateProfile(values)
    .then(function () {
      return true;
      // Update successful.
    })
    .catch(function (error) {
      return false;
      // An error happened.
    });
};

//  ! Database
export const FirebaseDatabase = firebase.database();

// ! Change email address
// var user = firebase.auth().currentUser;

// user
//   .updateEmail('user@example.com')
//   .then(function () {
//     // Update successful.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });

// ! send a verification email
// var user = firebase.auth().currentUser;

// user
//   .sendEmailVerification()
//   .then(function () {
//     // Email sent.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });

// ! //Update password:
// var user = firebase.auth().currentUser;
// var newPassword = getASecureRandomPassword();

// user
//   .updatePassword(newPassword)
//   .then(function () {
//     // Update successful.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });

//  ! Password reset email
// var auth = firebase.auth();
// var emailAddress = 'user@example.com';

// auth
//   .sendPasswordResetEmail(emailAddress)
//   .then(function () {
//     // Email sent.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });

// ! Delete a user :
// var user = firebase.auth().currentUser;

// user
//   .delete()
//   .then(function () {
//     // User deleted.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });

//  ! Re-authenticate for sensitive actions
// var user = firebase.auth().currentUser;
// var credential;

//  ! Prompt the user to re-provide their sign-in credentials

// user
//   .reauthenticateWithCredential(credential)
//   .then(function () {
//     // User re-authenticated.
//   })
//   .catch(function (error) {
//     // An error happened.
//   });
