import { useState } from "react";
import "./FAQ.scss";
import { AddButton, EditButton } from "./utils/FBCrud";
import UserInputForm from "./utils/UserInputForm";

function FAQ(props) {
  // TODO: FAQ
  const baseUserInputs = {
    Name: "",
    answer: "",
    url: "",
    urlName: "",
  };
  const [questions, setQuestions] = useState([]);
  const [questionLength, setQuestionLength] = useState(0);
  const [userInputs, setUserinputs] = useState(baseUserInputs);
  const [userInputEdit, setUserInputEdit] = useState(false);
  const [focusInputs, setFocusInputs] = useState({
    visibility: "hidden",
    zIndex: "-500",
  });
  const [scrollY, setScrollY] = useState(0);

  if (focusInputs.visibility !== "hidden" && window.scrollY - scrollY !== 0) {
    setScrollY(window.scrollY);
  }

  var style_inputContainer = {
    zIndex: focusInputs.zIndex,
  };

  var style_inputForm = {
    visibility: focusInputs.visibility,
    top: (50 - scrollY).toString() + "px",
  };

  if (props.data && Object.keys(props.data).length !== questionLength) {
    setQuestions(Object.keys(props.data));
    setQuestionLength(Object.keys(props.data).length);
  }

  const InputBox = (type, item) => {
    if (type === "add") {
      setUserInputEdit(false);
    } else {
      setUserInputEdit(true);
    }

    let values;
    let dir = item.split("/");
    let itemLength = item.split("/").length;
    switch (itemLength) {
      case 1:
        values = {
          path: dir,
          Name: "",
          answer: "",
          url: "",
          urlName: "",
        };

        setUserinputs(values);
        break;

      case 2:
        values = {
          path: dir,
          Name: dir[1],
          answer: props.data[dir[1]].answer,
          url: props.data[dir[1]].url,
          urlName: props.data[dir[1]].urlName,
        };
        setUserinputs(values);
        break;

      default:
        break;
    }
    setFocusInputs({ visibility: "visible", zIndex: "500000000000" });
  };

  let QnA = (question) => {
    if (props.data[question]) {
      let itemClass;
      let answerClass;
      if (questions.indexOf(question) === 0) {
        itemClass = "accordion button accoTop noTouch";
        answerClass = "panel noTouch";
      } else if (questions.indexOf(question) === questions.length - 1) {
        itemClass = "accordion button accoBottom noTouch";
        answerClass = "panel bottom noTouch";
      } else {
        itemClass = "accordion button noTouch";
        answerClass = "panel";
      }

      return (
        <div key={question.concat(props.data[question])}>
          {props.admin && (
            <EditButton
              item={"FAQ/".concat(question)}
              sig={props.admin.Key}
              key={question.concat(props.data[question].answer)}
              callback={InputBox}
            />
          )}
          <button
            className={itemClass}
            onClick={(e) => {
              var panel = e.target.nextElementSibling;
              var button = e.target;

              if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
                button.style.color = "#fff";
                button.style.backgroundColor = "#051308af";
              } else {
                panel.style.maxHeight = panel.scrollHeight + 20 + "px";
                console.log(panel.scrollHeight + 20);
                button.style.color = "#00ff37af";
                button.style.backgroundColor = "#051308";
              }
            }}>
            {question}
          </button>
          <div className={answerClass}>
            <p style={{ color: "var(--grey0)" }}>
              {props.data[question].answer}
            </p>
            {props.data[question].url && (
              <p style={{ padding: "0 0 1.5rem 0" }}>
                <a
                  href={props.data[question].url}
                  target="_blank"
                  rel="noreferrer">
                  {props.data[question].urlName
                    ? props.data[question].urlName
                    : "Link"}
                </a>
              </p>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {props.hasInitialised && (
        <div className="FAQcontainer">
          <UserInputForm
            focusInputs={focusInputs}
            setUserinputs={setUserinputs}
            baseUserInputs={baseUserInputs}
            userInputs={userInputs}
            setFocusInputs={setFocusInputs}
            style_inputForm={style_inputForm}
            style_inputContainer={style_inputContainer}
            userInputEdit={userInputEdit}
          />
          <div className="container">
            <div className="spacer"></div>
            <h1 style={{ textAlign: "center", width: "1", fontSize: "2rem" }}>
              Frequently asked questions:
            </h1>
            <div className="accordionContainer">
              {props.data
                ? questions.map(QnA)
                : "There are currently no questions"}
            </div>
            {props.admin && (
              <AddButton
                item="FAQ"
                sig={props.admin.Key}
                key={"FAQ"}
                callback={InputBox}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FAQ;
