import { create, remove, update } from "./FBCrud";
// import "./UserInputForm.scss";

export default function UserInputForm(props) {
  // * /////////////////// Conditional text tags ///////////////////

  let style_cancel = {
    backgroundColor: "var(--grey2)",
    color: "black",
  };

  let style_remove = {
    backgroundColor: "red",
    color: "black",
  };

  let style_submit = {
    backgroundColor: "green",
    color: "black",
  };

  function updater() {
    update(props.userInputs, cancel);
  }

  function submit() {
    create(props.userInputs, cancel);
  }

  function rem() {
    remove(props.userInputs, cancel);
  }

  function cancel() {
    props.setUserinputs(props.baseUserInputs);
    props.setFocusInputs({ visibility: "hidden", zIndex: "-50000" });
  }

  return (
    <>
      <div
        className="inputContainer1"
        style={props.style_inputContainer}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            cancel();
          }
          if (e.key === "Enter") {
            submit();
          }
        }}>
        <div className="inputForm1" style={props.style_inputForm}>
          {props.userInputs.Name !== undefined && (
            <>
              <label htmlFor="nameInput">Question:</label>
              <input
                id="nameInput"
                type="text"
                name="Name"
                disabled={props.userInputEdit ? true : false}
                value={props.userInputs.Name}
                placeholder="Question"
                onChange={(e) => {
                  let key = e.target.name;
                  let value = e.target.value;
                  props.setUserinputs((prevVal) => {
                    return {
                      ...prevVal,
                      [key]: value,
                    };
                  });
                }}
              />
            </>
          )}
          {props.userInputs.answer !== undefined && (
            <>
              <label htmlFor="answerInput">Answer:</label>
              <textarea
                name="answer"
                id="answerInput"
                type="text"
                value={props.userInputs.answer}
                placeholder="Provide an answer to the question"
                onChange={(e) => {
                  let key = e.target.name;
                  let value = e.target.value;
                  props.setUserinputs((prevVal) => {
                    return {
                      ...prevVal,
                      [key]: value,
                    };
                  });
                }}
              />
            </>
          )}

          {props.userInputs.url !== undefined && (
            <>
              <label htmlFor="urlInput">URL:</label>
              <input
                name="url"
                id="urlInput"
                type="text"
                value={props.userInputs.url}
                placeholder="URL to content"
                onChange={(e) => {
                  let key = e.target.name;
                  let value = e.target.value;
                  props.setUserinputs((prevVal) => {
                    return {
                      ...prevVal,
                      [key]: value,
                    };
                  });
                }}
              />
            </>
          )}

          {props.userInputs.urlName !== undefined && (
            <>
              <label htmlFor="urlNameInput">URL Name:</label>
              <input
                name="urlName"
                id="urlNameInput"
                type="text"
                value={props.userInputs.urlName}
                placeholder="Default is 'Link'"
                onChange={(e) => {
                  let key = e.target.name;
                  let value = e.target.value;
                  props.setUserinputs((prevVal) => {
                    return {
                      ...prevVal,
                      [key]: value,
                    };
                  });
                }}
              />
            </>
          )}
          {props.userInputEdit ? (
            <button
              style={style_submit}
              onClick={() => {
                updater();
              }}>
              Update
            </button>
          ) : (
            <button
              style={style_submit}
              onClick={() => {
                submit();
              }}>
              Add
            </button>
          )}

          {props.userInputEdit && (
            <button
              style={style_remove}
              onClick={() => {
                rem();
              }}>
              Remove
            </button>
          )}
          <button
            style={style_cancel}
            onClick={() => {
              cancel();
            }}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
