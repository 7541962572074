import { db } from "../../../sessionManager";

export function create(data, callback) {
  let urlpath;
  let URLName;
  if (
    data.img === "" ||
    data.img === "false" ||
    data.img === false ||
    data.img === "fals" ||
    data.img === "fase"
  ) {
    urlpath = "";
    URLName = "";
  } else {
    urlpath = data.url;
    URLName = data.urlName;
  }

  let basePath;
  let defaultVals;
  let currentPath;

  defaultVals = {
    answer: data.answer,
    url: urlpath,
    urlName: URLName,
  };
  currentPath = data.path.join("/");
  basePath = "WebPageData/".concat(currentPath + "/" + data.Name);

  console.log("setting data");
  return db.ref(basePath).set(defaultVals).then(callback()).catch(console.log);
}

export function update(data, callback) {
  let urlpath;
  let URLName;
  if (
    data.url === "" ||
    data.url === "false" ||
    data.url === false ||
    data.url === "fals" ||
    data.url === "fase"
  ) {
    urlpath = "";
    URLName = "";
  } else {
    urlpath = data.url;
    URLName = data.urlName;
  }
  let basePath;
  let defaultVals;

  defaultVals = {
    [data.Name]: {
      answer: data.answer,
      url: urlpath,
      urlName: URLName,
    },
  };

  basePath = "WebPageData/FAQ/".concat(data.Name);

  console.log("Updating data");
  return db
    .ref(basePath)
    .update(defaultVals[data.Name])
    .then(callback())
    .catch(console.log);
}

export function remove(data, callback) {
  let basePath;

  basePath = "WebPageData/FAQ/".concat(data.Name);

  console.log("Removing data");
  return db.ref(basePath).set(null).then(callback()).catch();
}

export function EditButton(props) {
  let item = props.item;
  let key = props.sig;
  let cb = props.callback;
  if (key !== undefined && key === "LoopStAdmin") {
    return (
      <>
        <div
          className="editButton"
          style={{ border: "1px solid white" }}
          onClick={() => {
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            cb("edit", item);
          }}>
          <img className="editImg" src="edit.png" alt="edit-img" />
        </div>
      </>
    );
  } else {
    return null;
  }
}

export function AddButton(props) {
  let item = props.item;
  let key = props.sig;
  let cb = props.callback;
  if (key !== undefined && key === "LoopStAdmin") {
    return (
      <div
        className="addButton"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          cb("add", item);
        }}>
        <img className="editImg" src="add.png" alt="add-img" />
      </div>
    );
  } else {
    return null;
  }
}
