import React from "react";
import "./Home.scss";
import hero from "./gr-hero-logo.png";
import aboutImg from "./petr-magera-mg-bg-md.jpg";

class Home extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  componentDidMount() {
    if (!this.props.hasInitialised) {
      setTimeout(() => {
        this.growHR("hr1");
      }, 3000);
    } else {
      this.growHR("hr1");
    }
  }
  componentWillUnmount() {
    clearTimeout(this.hrTimeOut);
  }

  growHR = (hrID) => {
    document.getElementById(hrID).classList.add("grow");
  };

  render() {
    return (
      <div className={!this.props.hasInitialised ? "scrollLock" : ""}>
        <section id="hero" className="hero">
          <div className="div2">
            <div className="hero-content">
              <img className="hero-logo" src={hero} alt="" draggable="false" />
              <div className="hr">
                <hr id="hr1" className="trans--grow hr1" />
              </div>
              <div className="company-mission">
                <p>
                  Green Ratio is a team of dedicated, innovative members
                  motivated to enhance the quality of nutrition in such a way
                  which is both affordable and encourages growth through
                  sustainability.
                </p>
              </div>
              <a href="#footer">
                <button
                  id="contact-btn"
                  onClick={() => {
                    let height = document.body.offsetHeight;
                    window.scrollTo({
                      top: height,
                      left: 0,
                      behavior: "smooth",
                    });
                    this.hrTimeOut = setTimeout(() => {
                      document.getElementById("contactsect").style.color =
                        "green";
                      document.getElementById(
                        "contactDiv"
                      ).style.backgroundColor = "#051308a6";
                      setTimeout(() => {
                        document.getElementById("contactsect").style.color =
                          "#fff";
                        document.getElementById(
                          "contactDiv"
                        ).style.backgroundColor = "";
                        setTimeout(() => {
                          document.getElementById("contactsect").style.color =
                            "green";
                          document.getElementById(
                            "contactDiv"
                          ).style.backgroundColor = "#051308a6";
                          setTimeout(() => {
                            document.getElementById("contactsect").style.color =
                              "#fff";
                            document.getElementById(
                              "contactDiv"
                            ).style.backgroundColor = "";
                            setTimeout(() => {
                              document.getElementById(
                                "contactsect"
                              ).style.color = "green";
                              document.getElementById(
                                "contactDiv"
                              ).style.backgroundColor = "#051308a6";
                              setTimeout(() => {
                                document.getElementById(
                                  "contactsect"
                                ).style.color = "#fff";
                                document.getElementById(
                                  "contactDiv"
                                ).style.backgroundColor = "";
                              }, 300);
                            }, 300);
                          }, 300);
                        }, 300);
                      }, 300);
                    }, 1000);
                  }}>
                  Contact Us
                </button>
              </a>
              <h4>
                <em>Paving the way to affordable nutrition for all.</em>
              </h4>
            </div>
          </div>
          <div className="div3"></div>
        </section>

        <section id="about">
          <div className="div1"></div>
          <div className="div2">
            <div className="about-content">
              <h1>About Us</h1>
              <p>
                With our sustainable operations we deliver produce that
                efficiently enhances your lifestyle. Initially we are achieving
                this with fresh, flavourful, and nutrient-dense microgreens at
                an affordable price.
                <br />
                <br />
                Our medium-term goal is to have contributed towards communities
                living a sustainable lifestyle and to help form more natural
                habitats flourish in which we co-exist.
              </p>
              <img
                className="about-img"
                src={aboutImg}
                alt="pretty-img"
                draggable="false"
              />
              <p>
                Currently growing microgreens with racks and trays in a
                controlled environment being lit by LED lighting. Continuously
                improving our yield per cubic meter of growing space. <br />
                <br />
                We are systematically working towards improving sustainability
                by incorporating renewable energy i.e., solar power as well as
                researching and developing different hydroponic and aeroponic
                techniques. <br />
                <br />
                At Green Ratio we create controlled environments by using
                hardware and software to monitor and regulate ideal growing
                conditions.
              </p>
              <p>
                We research methods to implement irrigation systems that use
                water efficiently i.e., incorporating different hydroponic
                methods where applicable, drip irrigation, collecting and
                re-collecting water as well as incorporating solar power to
                light up the night landscape with modern features such as LED
                lighting techniques. <br />
                <br />
                And by promoting landscaping that devotes a percentage of the
                garden to grow produce with hydroponic and/or conventional
                methods for workers and local communities, while educating the
                community in maintaining such gardens, we aim to create a more
                sustainable and abundant yields.
              </p>
              <h2>
                <br />
                <br />

                <em>Collaboration</em>
              </h2>
              <p style={{ textAlign: "center" }}>
                Green Ratio partners with Lourens Nursery
                <em> (owned by Marius Lourens) </em>
                to manage the Landscaping operations. Lourens Nursery enriches
                the landscaping division at Green Ratio with 20 years of
                experience and a strong active client base of 13 garden service
                contracts among 9 clients including: DHL/Unilever, SKF and
                Griffin Holdings. Please refer to the Company Profile of Lourens
                Nursery for further detail. The collaboration consists of Green
                Ratio assisting Lourens Nursery to incorporate our innovative
                and sustainable landscaping methods under the guidance of
                Lourens Nursery, having years of landscaping experience.
              </p>
            </div>
          </div>
          <div className="div3"></div>
        </section>
      </div>
    );
  }
}

export default Home;
