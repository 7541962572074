import "./Team.scss";
export default function Team(props) {
  return (
    <>
      <div
        className={
          props.hasInitialised ? "aboutContainer" : "aboutContainer scrollLock "
        }>
        <section id="team" className="hero">
          <div className="div2 team-content">
            <div>
              <h1>Meet the Team</h1>
            </div>
            <div className="card Aarde">
              <div className="header">
                <h3>JD van Aarde</h3>
              </div>
              <div className="card-container">
                <p>
                  Director and Founding Partner. Leading Company Strategy and
                  Operations to meet goals.
                </p>
              </div>
            </div>
            <div className="card Goede">
              <div className="header">
                <h3>Johann de Goede</h3>
              </div>
              <div className="card-container">
                <p>
                  Director and Founding Partner. HOD Research and Development.
                </p>
              </div>
            </div>
            <div className="card Toit">
              <div className="header">
                <h3>Tertius du Toit</h3>
              </div>
              <div className="card-container">
                <p>
                  Director and Founding Partner. Construction, Maintenance, and
                  Technological Research alongside IT.
                </p>
              </div>
            </div>
            <div className="card McIntyre">
              <div className="header">
                <h3>Michael McIntyre</h3>
              </div>
              <div className="card-container">
                <p>
                  Director and Founding Partner. <br />
                  HOD Control Systems and inhouse IT development.
                </p>
              </div>
            </div>
          </div>
          <div className="div3"></div>
        </section>
      </div>
    </>
  );
}
