import React, { useEffect, useState } from "react";
import "./Login.scss";

export default function Login(props) {
  const [userInputs, setUserInputs] = useState({ email: "", password: "" });
  const [loading, setloadingStatus] = useState({
    attempt: false,
    signedIn: false,
  });

  useEffect(() => {
    if (props.isSignedIn) {
      setloadingStatus({ attempt: false, signedIn: true });
    }
  }, [props]);

  const handleText = (event) => {
    let key = event.target.name;
    let value = event.target.value;
    setUserInputs((prevVals) => {
      return {
        ...prevVals,
        [key]: value,
      };
    });
  };

  const failedAttempt = (value) => {
    setloadingStatus((prevval) => {
      return {
        ...prevval,
        attempt: false,
      };
    });

    const { message } = value;
    alert("Login error:\n" + message);
  };

  const submitLogin = async () => {
    setloadingStatus((prevval) => {
      return {
        ...prevval,
        attempt: true,
      };
    });
    props.loginFunc(userInputs.email, userInputs.password, failedAttempt);
  };

  const submitLogOut = () => {
    setloadingStatus((prevval) => {
      return {
        ...prevval,
        attempt: true,
      };
    });
    props.signOutFunc();
  };

  const adminData = (
    <div
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          submitLogin();
        }
      }}>
      <label htmlFor="emailInput">Email</label>
      <br />
      <input
        id="emailInput"
        name="email"
        placeholder="John.Doe@example.com"
        value={userInputs.email}
        type="text"
        onChange={handleText}
      />
      <label htmlFor="passwordInput">Password</label>
      <br />
      <input
        id="passwordInput"
        name="password"
        placeholder="Password"
        value={userInputs.password}
        type={"password"}
        onChange={handleText}
      />
    </div>
  );

  // const alreadySignedIn = (
  //   <>
  //     <h1>You are already signed in.</h1>
  //   </>
  // );

  const clientData = <h1>You are logged in :)</h1>;
  const LoadingDiv = (
    <div className="LoadingContainer">
      <h1>Please wait</h1>
    </div>
  );

  return (
    <>
      <div className="container">
        <div className="spacer"></div>
        {loading.attempt ? (
          LoadingDiv
        ) : (
          <div className="signInForm">
            {!props.isSignedIn ? adminData : clientData}
            {props.isSignedIn ? (
              <button onClick={submitLogOut}>Log out</button>
            ) : (
              <button onClick={submitLogin}>Submit</button>
            )}
          </div>
        )}
      </div>
    </>
  );
}
