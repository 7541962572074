export default function NavToggleButton(props) {
  // * /////////////////// Conditional text tags ///////////////////

  return (
    <>
      <span className={props.toggle ? "navTrigger active" : "navTrigger"}>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </>
  );
}

// export default List;
