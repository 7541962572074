import Home from "./components/Home/Home";
// import Menu from "./components/Menu/Menu";
import Team from "./components/Team/Team";
// import Blog from "./components/Blogs/Blog";
import TopNavBar from "./components/Navbar/TopNavBar";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import Preloader from "./components/Preloader/Preloader";
import PleaseWait from "./components/PleaseWait/PleaseWait";

// import coverPattern from "./imgs/gr-bg-hero-pattern-dark.png";
// import coverImg from "./imgs/colby-mg-dark-md.jpg";

import FAQ from "./components/FAQ/FAQ";
import BlogPager from "./components/BlogPager/BlogPager";

import { BrowserRouter, Switch, Route, useParams } from "react-router-dom";
import "./App.scss";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  connectToAuth,
  // FBgetUserData,
  FirebaseDatabase,
  FBlogIn,
  signOut,
} from "./sessionManager";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasInitialised: false,
      scrolled: false,
      user: null,
      data: { MainPage: null, Menu: null, Events: null, FAQ: null },
      admin: null,
      english: true,
      eventRoutes: null,
    };

    this.loginFunc = FBlogIn;
    this.signOutFunc = signOut;

    this.Helmet = (
      <Helmet>
        <meta charSet="utf-8" />
        <title>GreenRatio</title>
        <meta
          name="keywords"
          content="Micro, greens, micro-greens, sustainable, produce, "
        />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
    );

    // this.EventRoutes = () => {
    //   if (
    //     this.state.eventRoutes !== null &&
    //     this.state.eventRoutes.length !== 0
    //   ) {
    //     this.state.eventRoutes.forEach((event) => {
    //       return (
    //         <Route
    //           path={"/events/:id"}
    //           children={
    //             <>
    //               <EventPager
    //                 param={useParams}
    //                 data={this.state.data.Events}
    //                 scrolled={this.state.scrolled}
    //                 isSignedIn={this.state.user}
    //                 admin={this.state.admin}
    //                 title={event}
    //               />
    //               {this.state.hasInitialised && (
    //                 <Footer admin={this.state.admin} />
    //               )}
    //             </>
    //           }
    //         />
    //       );
    //     });
    //   }
    // };

    this.switchContent = (hasInitialised) => {
      return (
        <Switch>
          <Route exact path="/">
            <Home
              hasInitialised={hasInitialised}
              scrolled={this.state.scrolled}
              isSignedIn={this.state.user}
              admin={this.state.admin}
            />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route exact path="/FAQ">
            <FAQ
              data={this.state.data.FAQ}
              admin={this.state.admin}
              hasInitialised={this.state.hasInitialised}
            />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route exact path="/store">
            {/* <Menu
              hasInitialised={hasInitialised}
              isSignedIn={this.state.user}
              Menu={this.state.data.Menu}
              scrolled={this.state.scrolled}
              admin={this.state.admin}
            /> */}
            <PleaseWait />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route exact path="/team">
            <Team hasInitialised={this.state.hasInitialised} />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route exact path="/blog">
            {/* <Blog
              data={this.state.data.Events}
              isSignedIn={this.state.user}
              hasInitialised={hasInitialised}
              admin={this.state.admin}
            /> */}
            <PleaseWait />

            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route exact path="/login">
            <Login
              hasInitialised={hasInitialised}
              loginFunc={FBlogIn}
              signOutFunc={signOut}
              isSignedIn={this.state.user}
              admin={this.state.admin}
            />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>
          <Route path="/events/:id">
            <BlogPager
              params={useParams}
              data={this.state.data.Events}
              scrolled={this.state.scrolled}
              isSignedIn={this.state.user}
              admin={this.state.admin}
            />
            {this.state.hasInitialised && <Footer admin={this.state.admin} />}
          </Route>

          {/* {this.EventRoutes()} */}
          {/* <Route render={() => <Redirect to="/" />} /> */}
        </Switch>
      );
    };

    this.switchLanguage = (value) => {
      this.setState({ english: value });
    };

    this.updateData = (key, dataVal) => {
      this.setState({ [key]: dataVal });

      // let keys = Object.keys(dataVal);
      // if (dataVal["Events"] !== undefined) {
      //   let list1 = Object.keys(dataVal["Events"]["Once-off"]);
      //   let list2 = Object.keys(dataVal["Events"]["Recurring"]);
      //   let listTotal = list1.concat(list2);
      //   this.setState({ eventRoutes: listTotal });
      // }
    };

    this.scrollFunction = (val) => {
      this.setState({ scrolled: val });
    };

    this.sessionHandler = async (data) => {
      if (data) {
        this.setState({ user: true });

        // window.location.href = "http://localhost:3000";
      } else {
        this.setState({ user: false });
      }
    };

    this.getData = () => {
      var data;
      var admin;
      let datacheck = FirebaseDatabase.ref("WebPageData");
      let adminCheck = FirebaseDatabase.ref("AdminData");

      try {
        datacheck.on("value", (snapshot) => {
          data = snapshot.val();
          this.updateData("data", data);
        });

        adminCheck.on("value", (snapshot) => {
          admin = snapshot.val();
          this.updateData("admin", admin);
        });
      } catch (err) {
        console.log(`Failed to retrieve data[0]: ${err}`);
      }
    };
  }

  newInitTimer = () => {
    this.InitTimer = setTimeout(() => {
      this.setState({ hasInitialised: true });
      document.getElementById(
        "coverPattern"
      ).style.height = `${document.body.scrollHeight}px`;
      console.log(document.body.scrollHeight);
    }, 3000);
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      document.getElementById(
        "coverPattern"
      ).style.height = `${document.body.scrollHeight}px`;
    });

    window.onresize = () => {
      document.getElementById(
        "coverPattern"
      ).style.height = `${document.body.scrollHeight}px`;
    };
    this.newInitTimer();
    connectToAuth(this.sessionHandler);
    this.getData();
    this.delayedCheck = setInterval(() => {
      console.log("no data found, trying again");
      if (this.state.data.MainPage === null || this.state.data.Menu === null) {
        this.getData();
      } else {
        clearInterval(this.delayedCheck);
      }
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.InitTimer);
    clearInterval(this.delayedCheck);
    clearTimeout(this.loaderOpacityTimer);
  }

  render() {
    return (
      <HelmetProvider>
        <BrowserRouter>
          <div id="coverImg" />
          <div id="coverPattern"></div>

          {this.Helmet}
          <TopNavBar
            scrollFunction={this.scrollFunction}
            isSignedIn={this.state.user}
            signOutFunc={this.signOutFunc}
            switchLanguage={this.switchLanguage}
          />
          {!this.state.hasInitialised && <Preloader />}
          {this.switchContent(this.state.hasInitialised)}
        </BrowserRouter>
      </HelmetProvider>
    );
  }
}

export default App;
