import React from "react";
import { NavLink } from "react-router-dom";
import NavToggleButton from "./utils/NavToggleButton";
import navLogo from "./gr-nav-logo.png";
import "./TopNavBar.scss";
import topNavToggleLogo from "./gr-toggle-bg.png";
document.body.classList.add("dark");

class TopNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.matchMedia("(min-width: 680px)").matches,
      scrolled: false,
      opacity: 100,
      toggle: false,
      zIndex: 9999,
      english: true,
    };
    this.navbarClasses = [""];
    this.offset = 0;

    this.scrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    this.navItems = (width) => {
      return (
        <div
          // style={{ backgroundImage: topNavToggleLogo }}
          className={!width ? "TopNavToggledItems" : "TopNavItems"}>
          <NavLink
            onClick={() => {
              this.setState({ toggle: false });
              this.scrollToTop();
            }}
            className={!width ? "TopNavToggledItem" : "TopNavItem"}
            activeClassName="is-active"
            to="/store"
            exact>
            Store
          </NavLink>
          {!width && (
            <img
              src={topNavToggleLogo}
              className="TopNavToggledLogo"
              alt="Navbar-logo"
            />
          )}
          <NavLink
            onClick={() => {
              this.setState({ toggle: false });
              this.scrollToTop();
            }}
            className={!width ? "TopNavToggledItem" : "TopNavItem"}
            activeClassName="is-active"
            to="/blog"
            exact>
            Blog
          </NavLink>
          <NavLink
            onClick={() => {
              this.setState({ toggle: false });
              this.scrollToTop();
            }}
            className={!width ? "TopNavToggledItem" : "TopNavItem"}
            activeClassName="is-active"
            to="/team"
            exact>
            Our Team
          </NavLink>
          {this.props.isSignedIn && (
            <NavLink
              onClick={() => {
                this.setState({ toggle: false });
                this.scrollToTop();
                this.props.signOutFunc();
              }}
              className={!width ? "TopNavToggledItem" : "TopNavItem"}
              activeClassName="is-active"
              to="/login"
              exact>
              Log Out
            </NavLink>
          )}
        </div>
      );
    };

    this.handleScroll = () => {
      const offset = window.scrollY;

      if (offset === 0) {
        this.setState({ scrolled: false, opacity: 100, zIndex: 9999 });
        this.props.scrollFunction(false);
      } else {
        if (offset - this.offset > 0) {
          this.setState({
            scrolled: true,
            opacity: 0,
            toggle: false,
            zIndex: -10,
          });
          this.props.scrollFunction(true);
        } else {
          this.props.scrollFunction(false);
          this.setState({ scrolled: false, opacity: 100, zIndex: 9999 });
        }
      }
      this.offset = offset;
    };

    this.toggleBurger = () => {
      this.setState({ toggle: !this.state.toggle });
      // document.getElementById("TopNavToggledDiv").style;
      // console.log(document.getElementById("TopNavToggledDiv"));
    };
  }

  componentDidMount() {
    const handler = (e) => {
      this.setState({ width: e.matches });
    };
    window.matchMedia("(min-width: 900px)").addListener(handler);
    window.addEventListener("scroll", this.handleScroll);
  }

  render() {
    return (
      <header>
        <nav>
          <div
            className="TopNavBar"
            style={{ opacity: this.state.opacity, zIndex: this.state.zIndex }}>
            <NavLink
              className="TopNavLogo noTouch"
              onClick={() => {
                this.setState({ toggle: false });
                this.scrollToTop();
              }}
              activeClassName="is-active"
              to="/"
              exact>
              <img className="Logo" src={navLogo} alt="Home" />
            </NavLink>

            {this.state.width ? (
              this.navItems(this.state.width)
            ) : (
              <div onClick={this.toggleBurger} className="noTouch">
                <NavToggleButton toggle={this.state.toggle} />
              </div>
            )}
            {!this.state.width && this.state.toggle && (
              <div className="TopNavCollapse">
                {this.navItems(this.state.width)}
              </div>
            )}
          </div>
        </nav>
      </header>
    );
  }
}

export default TopNavBar;
