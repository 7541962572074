import React from "react";
import "./Preloader.scss";
import icon1 from "./ico1.png";
import icon2 from "./ico2.png";
import icon3 from "./ico3.png";
import icon4 from "./ico4.png";
import icon5 from "./ico5.png";
import icon6 from "./ico6.png";
import icon7 from "./ico7.png";

export default class Preloader extends React.Component {
  constructor(props) {
    super(props);
    this.icons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7];
    this.currentNumber = 0;
    this.state = {
      number: 0, // Number from 0 to 3 for 4 icons
      icon: 1, // Number from 0 to 6 for all 7 icons
    };
  }

  componentDidMount() {
    this.intervalState = setInterval(() => {
      if (!this.props.hasInitialised) {
        let newNumber = this.state.number;
        let newIcon = this.state.icon;
        if (newNumber === 3) {
          newNumber = 0;

          if (newIcon === 7) {
            newIcon = 1;
          } else {
            newIcon++;
          }
        } else {
          newNumber++;
        }
        this.setState({ number: newNumber, icon: newIcon });
      } else {
        this.setState({ number: 5 });
        setTimeout(() => {
          this.setState({ fade: true });
        });
      }
    }, 150);
  }

  componentWillUnmount() {
    clearInterval(this.intervalState);
  }

  logo1 = (number, icon) => {
    if (icon > 7) {
      icon = icon - 7;
    }

    let iconPath = this.icons[icon - 1];
    let imgStyle;

    if (number === 0) {
      imgStyle = {
        transform: "rotate(90deg)",
        width: "5rem",
        height: "5rem",
        opacity: this.state.number !== 0 ? "0.3" : "1",
      };
    } else if (number === 1) {
      imgStyle = {
        transform: "rotate(0deg)",
        width: "5rem",
        height: "5rem",
        opacity: this.state.number !== 1 ? "0.3" : "1",
      };
    } else if (number === 2) {
      imgStyle = {
        transform: "rotate(270deg)",
        width: "5rem",
        height: "5rem",
        opacity: this.state.number !== 3 ? "0.3" : "1",
      };
    } else if (number === 3) {
      imgStyle = {
        transform: "rotate(180deg)",
        width: "5rem",
        height: "5rem",
        opacity: this.state.number !== 2 ? "0.3" : "1",
      };
    }

    return (
      <img
        className="bigLogo"
        style={imgStyle}
        src={iconPath}
        alt="Preloader-logo"
      />
    );
  };

  render() {
    return (
      !this.props.hasInitialised && (
        <div className="containingDiv">
          <div className="initDiv">
            {this.logo1(0, this.state.icon)}
            {this.logo1(1, this.state.icon + 1)}
            {this.logo1(2, this.state.icon + 2)}
            {this.logo1(3, this.state.icon + 3)}
          </div>
        </div>
      )
    );
  }
}
