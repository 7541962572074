import React from "react";
import { NavLink } from "react-router-dom";
import footerLogo from "./footer-icon-lg.png";
import instaLogo from "./ig.png";
import fblogo from "./facebook.png";
import linkedInLogo from "./l-in.png";

import "./Botnav.scss";
let date = new Date().getFullYear();
export default function Footer(props) {
  window.onscroll = function () {
    // if (window.screen.height)
    if (window.scrollY + window.innerHeight === document.body.offsetHeight) {
      growHR("hr2");
    }
    console.log();
  };
  const growHR = (hrID) => {
    document.getElementById(hrID).classList.add("grow");
  };

  return (
    <footer>
      <div className="container">
        {window.screen.width > 640 && (
          <div className="fLogo">
            <img
              className="footer-logo"
              src={footerLogo}
              alt=""
              draggable="false"
            />
          </div>
        )}

        <div className="Footer">
          <div id="contactDiv" className="FooterContainer">
            <h3 id="contactsect">Contact</h3>
            <a
              className="BotNavItem noTouch"
              href="mailto:info@greenratio.co.za">
              info@greenratio.co.za
            </a>
          </div>
          <div className="FooterContainer">
            <h3>FAQ</h3>
            <NavLink
              className="BotNavItem noTouch"
              activeClassName="is-active"
              to="/FAQ"
              exact>
              <div
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}>
                Q&A
              </div>
            </NavLink>
          </div>
          <div className="FooterContainer">
            <h3>Social media</h3>
            <div className="BotNavItem noTouch">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/green_ratio/">
                <img
                  className="socialMediaButton"
                  src={instaLogo}
                  alt="instagram"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedIn.com/company/green-ratio/">
                <img
                  className="socialMediaButton"
                  src={linkedInLogo}
                  alt="linkedIn"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/greenratio/">
                <img
                  className="socialMediaButton"
                  src={fblogo}
                  alt="facebook"
                />
              </a>
            </div>
          </div>
          <div className="FooterContainer">
            <h3>Admin</h3>
            <NavLink
              className="BotNavItem noTouch"
              activeClassName="is-active"
              to="/login"
              exact>
              <div
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}>
                {props.admin ? "Log out" : "Login"}
              </div>
            </NavLink>
          </div>
        </div>
        <div className="hr2">
          <hr id="hr2" className="trans--grow hr1" />
        </div>
        <div className="companyTag">
          <h3
            target="_blank"
            onClick={() => {
              window.open("http://www.alt-innovations.com");
            }}>
            © Alt-Innovations {date}
          </h3>
        </div>
      </div>
    </footer>
  );
}
