import "./BlogPager.scss";
function EventPager(props) {
  // TODO: Events page
  let EventItem = <h1>The page you are looking for could not be found.</h1>;
  let Name;
  let content;
  let img;
  let date;
  let meta1;
  let meta2;
  let meta3;
  let metaTwitter;
  let metaTwitter1;
  let metaTwitter2;
  let metaTwitter3;

  if (props.data) {
    let id = props.params();
    let OnceOffItems = Object.keys(props.data["Once-off"]);
    let RecurringItems = Object.keys(props.data["Recurring"]);

    if (OnceOffItems.indexOf(id.id) > -1) {
      Name = id.id;
      content = props.data["Once-off"][id.id].content;
      img = props.data["Once-off"][id.id].img;
      date = props.data["Once-off"][id.id].date;

      meta1 = document.createElement("meta");
      meta1.property = "og:image";
      meta1.content = img;
      meta2 = document.createElement("meta");
      meta2.property = "og:image:width";
      meta2.content = "180";
      meta3 = document.createElement("meta");
      meta3.property = "og:image:height";
      meta3.content = "110";
      document.getElementsByTagName("head")[0].appendChild(meta1, meta2, meta3);

      metaTwitter = document.createElement("meta");
      metaTwitter.name = "twitter:title";
      metaTwitter.content = Name;
      metaTwitter1 = document.createElement("meta");
      metaTwitter1.name = "twitter:card";
      metaTwitter1.content = date;
      metaTwitter2 = document.createElement("meta");
      metaTwitter2.name = "twitter:card";
      metaTwitter2.content = "www.loopstraat.com/events/" + id.id;
      metaTwitter3 = document.createElement("meta");
      metaTwitter3.name = "twitter:description";
      metaTwitter3.content = props.data["Once-off"][id.id].description;

      EventItem = (
        <div className="actualEvent">
          <span>
            <h1>{Name}</h1> <h1>{date}</h1>
          </span>
          {img && (
            <img
              src={img}
              style={{
                borderRadius: "10px",
                boxShadow: "5px 5px 5px black",
                maxWidth: "80vw",
              }}
              alt={Name + "-image"}
            />
          )}

          <p>{content}</p>
        </div>
      );
    } else if (RecurringItems.indexOf(id.id) > -1) {
      Name = id.id;
      content = props.data["Recurring"][id.id].content;
      img = props.data["Recurring"][id.id].img;
      date = props.data["Recurring"][id.id].date;

      meta1 = document.createElement("meta");
      meta1.property = "og:image";
      meta1.content = img;
      meta2 = document.createElement("meta");
      meta2.property = "og:image:width";
      meta2.content = "180";
      meta3 = document.createElement("meta");
      meta3.property = "og:image:height";
      meta3.content = "110";
      document.getElementsByTagName("head")[0].appendChild(meta1, meta2, meta3);

      metaTwitter = document.createElement("meta");
      metaTwitter.name = "twitter:title";
      metaTwitter.content = Name;
      metaTwitter1 = document.createElement("meta");
      metaTwitter1.name = "twitter:card";
      metaTwitter1.content = date;
      metaTwitter2 = document.createElement("meta");
      metaTwitter2.name = "twitter:card";
      metaTwitter2.content = "www.loopstraat.com/events/" + id.id;
      metaTwitter3 = document.createElement("meta");
      metaTwitter3.name = "twitter:description";
      metaTwitter3.content = props.data["Recurring"][id.id].description;

      EventItem = (
        <div className="actualEvent">
          <span>
            <h1>{Name}</h1> <h1>{props.data["Recurring"][id.id].repeat}</h1>
          </span>
          {img && (
            <img
              src={img}
              style={{
                borderRadius: "10px",
                boxShadow: "5px 5px 5px black",
                maxWidth: "80vw",
              }}
              alt={Name + "-image"}
            />
          )}

          <p>{content}</p>
        </div>
      );
    } else {
      console.log("It does not exist");
    }
  }

  // if (OnceOffItems.indexOf(id))
  return (
    <>
      <div className="container">
        <div className="spacer"></div>
        <div className="EventURLcontainer">
          {props.data && <h2>Loop presents:</h2>}
          {EventItem}
        </div>
      </div>
    </>
  );
}

export default EventPager;
