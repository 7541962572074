import logo from "./news-logo.png";
import "./PleaseWait.scss";

export default function PleaseWait(props) {
  return (
    <>
      <section id="news" className="news">
        <div className="div1"></div>
        <div className="div2">
          <div className="news-content">
            <img
              className="news-logo"
              src={logo}
              alt="GreenRatio-shield"
              draggable="false"
            />
            <h1>
              We are still <em>growing</em>...
            </h1>
            <p>Check this page regularly for any updates</p>
          </div>
        </div>
        <div className="div3"></div>
      </section>
    </>
  );
}
